import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyCD-45jCdrLMqK2le3APe5YBPxFsqE-LWY",
  authDomain: "efterskatt.firebaseapp.com",
  databaseURL: "https://efterskatt.firebaseio.com",
  projectId: "efterskatt",
  storageBucket: "efterskatt.appspot.com",
  messagingSenderId: "734546999984",
  appId: "1:734546999984:web:9bd90d7513d3399ad2d975",
  measurementId: "G-K25D18WZD0"
}

export const logEvent = event => 
  firebase.analytics().logEvent(event)

export const initFirebase = () => {
  firebase.initializeApp(firebaseConfig)
  logEvent('user_entered')
}

