import React, { useEffect } from "react"

import SEO from "../components/SEO"
import { initFirebase } from "../domain/firebase";

export default () => {

  useEffect(() => {
    initFirebase()
  }, [])

  return (<div>
    <SEO />
    🕵️‍♂️
  </div>
  )
}